.logo-wrapper{
    display: flex;
    align-items: center;
    @include max-mq(1200px){
        flex-direction: column-reverse;
        align-items: flex-start;
        .social{
            padding-left: 20px;
            padding-bottom: 20px;
        }
    }
    
    @include max-mq(480px){
        img{
            width: 100%;
        }
    }
}

.sticky{
    position: fixed;
    top: 0;
    width: 100%;
    padding: 15px 0;
    z-index: 50;
    @include max-mq(1200px){
        .social{
            display: none;
        }
    }
    .logo{
        img{
            width: 80%;
            @include max-mq(480px){
                width: 70%;
            }
        }
    }
}

.logo-wrapper{
    .logo{
        max-width: 219px;
        @include max-mq(767px){
            max-width: 336px;
        }
    }
}

.social{
    padding-left: 50px;
    @include max-mq(1200px){
        padding-left: 0;
        padding-bottom: 10px;
    }
    @include max-mq(1024px){
        display: none;
    }
    a{
        display: inline-block;
        padding: 0 3px;
        transition: all .4s;
        i{
            font-size: 20px;
            color: $light-blue;
            background: $icons-bkg;
            padding: 2px;
            text-align: center;
            font-size: 23px;
            width: 1.6em;
            text-align: center;
            line-height: 1.6em;
            border-radius: 50%;
            transition: all .4s;
            @include max-mq(1200px){
                font-size: 16px;
            }
        }
        &:hover{
            i{
                background: $dark-blue;
            }
        }
    }
}

header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $light-blue;
    padding: 25px 0 25px 25px;
    position: relative;
    @include max-mq(1024px){
        padding: 25px 5px;
    }
    @include max-mq(767px){
        padding: 35px 5px;
    }
    @include max-mq(480px){
        padding: 15px 5px;
    }
    .logo-wrapper{
        img{
            width: 100%;
            transition: all .4s;
            @include max-mq(480px){
                width: 80%;
            }
        }
    }
    .navigation{
        display: flex;
        justify-content: space-between;
        .blue-btn{
            position: absolute;
            top: 0;
            right: 0; 
            display: flex;
            align-items: center;
            height: 100%;
            padding: 0 50px;
            color: $main_white;
            font-size: 16px;
            font-weight: 600;
            text-transform: uppercase;
            transition: all .4s;
            box-sizing: border-box;
            border: 1px solid $dark-blue;
            &:hover{
                background: $main_white;
                color: $dark-blue;
            }
            @include max-mq(1024px){
                display: none;
            }
            i{
                display: inline-block;
                padding-left: 5px;
                color: $blue-arrow;
                position: absolute;
                right: 20px;
                top: 0;
                bottom: 0;
                margin: auto;
                height: 20px;
            }
        }
    }
    nav{
        position: relative;
        right: 0;
        display: flex;
        align-items: center;
        .blue-btn-hidden{
            display: none;
            align-items: center;
            padding: 20px 50px;
            color: $main_white;
            font-size: 26px;
            font-weight: 600;
            text-transform: uppercase;
            transition: all .4s;
            background: $dark-blue;
            border: 1px solid $dark-blue;
            @include max-mq(991px){
                font-size: 18px;
            }
            i{
                color: $menu-bkg;
            }
            &:hover{
                background: $main_white;
                color: $dark-blue;
            }
        
        }
        ul{
            @include max-mq(767px){
                margin-bottom: 5px;
            }
            li{
                display: inline-block;
                padding: 0 20px;
                position: relative;
                &:after{
                    content: "";
                    position: absolute;
                    width: 5px;
                    height: 5px;
                    border-radius: 50%;
                    background: $dark-blue;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    right: 0;
                }
                &:last-of-type{
                    &:after{
                        display: none;
                    }
                }
                a{
                    color: $main_white;
                    font-weight: 600;
                    font-size: 16px;
                    text-transform: uppercase;
                    letter-spacing: 1px;
                    &:hover{
                        color: $dark-blue;
                    }
                }
            }
        }
    }
    
}