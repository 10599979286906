.science{
    background: url("../images/science-hero-bkg.png");
    padding: 100px 0 140px;
    background-size: cover;
    background-position: center;
    @include max-mq(767px){
        padding: 170px 0 250px;
    }
    @include max-mq(480px){
        padding: 100px 0;
    }
}

// body.science-page{
//     overflow-x: hidden !important;
// }

.wider-section{
    .wider-section-wrapper{
        overflow: hidden;
    }
    max-width: 1590px;
    margin: 0 auto;
    padding: 20px 15px 0;
    @include max-mq(1200px){
        background: url("../images/brain-bkg.png");
        background-size: 160vh;
        background-position: top 20% left 20%;
        background-repeat: no-repeat;
        position: relative;
        z-index: 0;
        @include max-mq(768px){
            background-size: 100vh;
        }
        @include max-mq(767px){
            padding-bottom: 120px;
        }
        &:after{
            content: "";
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: rgba(255, 255, 255, .7);
        }
    }
    h2{
        text-align: center;
        color: $grey-paragraph;
        @include max-mq(1200px){
            position: relative;
            z-index: 5;
        }
    }
    .img-wrapper{
        text-align: center;
        padding: 145px 0;
        position: relative;
        
        @include max-mq(1200px){
            padding: 40px 0;
        }
        @include max-mq(768px){
            padding-bottom: 0;
        }
        @include max-mq(767px){
            padding-top: 30px;
        }
        img{
            @include max-mq(1600px){
                width: 60%;
            }
            @include max-mq(1200px){
                display: none;
            }
        }
    }
    .no-padding{
        // @include max-mq(1200px){
        //     padding: 0 ;
        // }
    }
    .blue-content{
        background: $blue-arrow;
        padding: 45px 65px;
        border-top-left-radius: 30px;
        border-bottom-right-radius: 30px;
        position: absolute;
        max-width: 1170px;
        width: 100%;
        left: 0;
        right: 0;
        margin: 0 auto;
        z-index: 2;
        @include max-mq(1280px){
            max-width: calc(100% - 60px);
        }
        @include max-mq(1024px){
            padding: 40px;
        }
    
        @include max-mq(767px){
            max-width: calc(100% - 90px);
            margin-top: 80px;
        }
        @include max-mq(580px){
            padding: 30px;
            max-width: calc(100% - 60px);
            margin-top: 60px;
        }
        @include max-mq(480px){
            margin-top: 40px;
            padding: 20px;
        }
        @include max-mq(400px){
            margin-top: 15px;
        }
        p{
            font-weight: 500;
            color: $main_white;
            font-size: 18px;
            line-height: 28px;
            @include max-mq(767px){
                font-size: 20px;
                line-height: 1.55em;
            }
            @include max-mq(400px){
                line-height: 1.4em;
            }
        }
    }

    .pathway-wrapper{
        @include max-mq(1200px){
            z-index: 1;
            position: relative;
        }
    }
    .single-pathway{
        width: 40%;
        position: absolute;
        
        @include max-mq(1200px){
            position: static;
            width: 100%;
            z-index: 2;
            display: flex;
            justify-content: flex-start;
            margin-bottom: 30px;
        }

        @include max-mq(480px){
            margin-bottom: 35px;
        }

        
        &:nth-of-type(1){
            top: 3%;
            left: 7%;
            @include max-mq(1600px){
                top: 7%;
                left: 8%;
            }
            @include max-mq(1444px){
                top: 10%;
            }
        }

        &:nth-of-type(2){
            top: 10%;
            right: -6%;
            @include max-mq(1600px){
                top: 12%;
                right: -4%;
            }
            @include max-mq(1444px){
                top: 14%;
            }
        }

        &:nth-of-type(3){
            top: 42%;
            right: -12%;
            .text-content{
                padding-right: 15px !important;
            }
            @include max-mq(1700px){
                right: -10%;
            }
            @include max-mq(1600px){
                right: -5%;
            }
        }

        &:nth-of-type(4){
            top: 78%;
            right: 0%;
            @include max-mq(1600px){
                top: 76%;
            }
            @include max-mq(1333px){
                top: 73%;
            }
        }

        &:nth-of-type(5){
            top: 71%;
            left: 0%;
            @include max-mq(1600px){
                top: 70%;
                left: 3%;
            }
            @include max-mq(1444px){
                top: 68%;
            }
        }
        &:nth-of-type(6){
            top: 38%;
            left: -13%;
            .text-content{
                padding-left: 15px !important;
            }
            @include max-mq(1700px){
                left: -9%;
            }
            @include max-mq(1600px){
                left: -6%;
            }
            @include max-mq(1333px){
                left: -5%;
            }
        }
        .wrapper-inner{
            display: flex;
            @include max-mq(1200px){
                width: 100%;
            }
            // @include max-mq(767px){
            //     justify-content: center;
            // }
            .icon{
                cursor: pointer;
                width: 124px;
                @include max-mq(1600px){
                    width: 100px;
                }
                @include max-mq(1200px){
                    width: 124px;
                    padding-left: 0;
                    padding-right: 15px;
                }
                @include max-mq(767px){
                    min-width: 160px;
                }
                @include max-mq(580px){
                    min-width: 0;
                    width: 75px;
                    padding-right: 5px;
                }
                img{
                    width: 100%;
                    display: flex;
                    @include max-mq(1200px){
                        max-width: 100%;
                    }
                }
            }
            .text-content{
                text-align: left;
                padding-left: 20px;
                padding-right: 45px;
                margin-left: 10px;
                padding-bottom: 15px;
                padding-top: 10px;
                @include max-mq(1800px){
                    width: 55%;
                    padding-right: 15px;
                }
                @include max-mq(1600px){
                    width: 65%;
                }
                @include max-mq(1200px){
                    width: calc(100% - 124px);
                    margin-left: 0;
                    margin-right: 0;
                    display: flex;
                    align-items: center;
                }
                @include max-mq(767px){
                    max-width: 465px;
                    width: 100%;
                    padding: 15px 20px;
                }

                @include max-mq(480px){
                    padding: 15px;
                }

                @include max-mq(580px){
                    max-width: calc(100% - 80px) !important;
                }
                .content-wrapper{
                    display: flex;
                    flex-direction: column;
                    max-width: 395px;
                    @include max-mq(1200px){
                        max-width: none;

                    }

                }
                h4{
                    text-transform: uppercase;
                    margin-bottom: 0;
                    line-height: 34px;
                    max-width: 300px;
                    margin-right: auto;
                    margin-top: 15px;
                    color: $grey-paragraph;
                    @include max-mq(1440px){
                        margin-top: 0;
                    }
                    @include max-mq(1333px){
                        font-size: 20px;
                        line-height: 26px;
                    }
                    @include max-mq(1200px){
                        max-width: 100%;
                        width: 100%;
                        margin-top: 0;
                        font-size: 24px;
                        display: inline-block;
                    }
                    @include max-mq(800px){
                        font-size: 20px;
                    }
                    @include max-mq(767px){
                        font-size: 30px;
                        line-height: 1.2em;
                    }
                    @include max-mq(580px){
                        font-size: 25px;
                    }
                    @include max-mq(480px){
                        font-size: 15px;
                    }
                }
                p{
                    display: none;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 30px;
                    width: 100%;
                    @include max-mq(1660px){
                        margin-top: 15px;
                        line-height: 26px;
                    }
                    @include max-mq(1333px){
                        font-size: 16px;
                        line-height: 22px;
                    }
                    @include max-mq(767px){
                        font-size: 24px;
                        line-height: 1.5em;
                    }

                    @include max-mq(480px){
                        font-size: 20px;
                        line-height: 1.3em;
                    }
                }
            }
        }

        .reversed{
            flex-direction: row-reverse;
            @include max-mq(1200px){
                flex-direction: row;
                width: 100%;
            }
            .icon{
                padding-right: 0;
                @include max-mq(1200px){
                    padding-right: 15px;
                }
                @include max-mq(580px){
                    padding-right: 5px;
                }
            }
            .content-wrapper{
                margin-left: auto;
                @include max-mq(1200px){
                    margin-left: 0;
                }
            }
            .text-content{
                text-align: right;
                padding-left: 45px;
                padding-right: 20px;
                margin-right: 10px;
                padding-bottom: 15px;
                padding-top: 10px;
                @include max-mq(1890px){
                    width: 55%;
                    padding-left: 15px;
                }
                
                @include max-mq(1600px){
                    width: 65%;
                }
                @include max-mq(1200px){
                    text-align: left;
                    width: calc(100% - 124px);
                    margin-left: 0;
                    margin-right: 0;
                    padding-right: 15px;
                    display: flex;
                    align-items: center;
                }
                @include max-mq(767px){
                    max-width: 465px;
                    width: 100%;
                    padding: 15px 20px;
                }

                @include max-mq(580px){
                    max-width: calc(100% - 80px) !important;
                }

                @include max-mq(480px){
                    padding: 15px;
                }
            
                h4{
                    margin-left: auto;
                    margin-right: 0;
                    margin-bottom: 0;
                    margin-top: 15px;
                    line-height: 34px;
                    color: $grey-paragraph;
                    @include max-mq(1440px){
                        margin-top: 0;
                    }
                    @include max-mq(1333px){
                        font-size: 20px;
                        line-height: 26px;
                    }
                    @include max-mq(1200px){
                        margin-left: 0;
                        margin-right: auto;
                        margin-top: 0;
                        font-size: 24px;
                    }
                    @include max-mq(800px){
                        font-size: 20px;
                    }

                    @include max-mq(767px){
                        font-size: 30px;
                        line-height: 1.2em;
                    }

                    @include max-mq(580px){
                        font-size: 25px;
                    }
                   
                    @include max-mq(480px){
                        font-size: 15px;
                    }
                }
            }
        }
        
    }
    .activePathway{
        background: $main_white;
        -webkit-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.2);
        box-shadow: 0px 0px 25px 5px rgba(0,0,0,0.2);
        position: relative;
        z-index: 3;
        transition: all .4s;
        @include max-mq(1800px){
            width: 55%;
        }
        @include max-mq(1600px){
            width: 65% !important;
        }
        @include max-mq(1200px){
            width: calc(100% - 124px) !important;
            margin-left: 10px;
        }

         @include max-mq(767px){
            max-width: 465px !important;
            width: 100%;
        }

        @include max-mq(580px){
            width: calc(100% - 80px) !important;
            max-width: 0 !important;
        }
       
        
        h4{
            color: $blue-title !important;
        }
        
    }
}


