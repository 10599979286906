html {
    .select2-container--default.select2-container--open.select2-container--below .select2-selection--single, 
    .select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
        border-radius: 22px 22px 0 0;
    }
    .select2-selection {
        outline: none;
    }
    .select2-container {
        .select2-selection--single {
            border-radius: 22px;
            height: 46px;
        }
    }
    .select2-results__option {
        padding: 10px 6px;
    }
    .select2-results {
        border-radius: 0px 0px 22px 22px;
    }
    .select2-container--default {
        .select2-results {
            border-radius: 0px 0px 22px 22px;
        }
        .select2-results {
            > .select2-results__options {
                border-radius: 0px 0px 22px 22px;
                max-height: 220px;
            }
        }
        .select2-selection--single {
            .select2-selection__rendered {
                line-height: 46px;
            }
            .select2-selection__arrow {
                height: 44px;
            }
        }
        .select2-results__option--highlighted[aria-selected] {
            background-color: $light-blue;
            color: white;
        }
        
    }
    .select2-container--open .select2-dropdown--below {
        border-radius: 0px 0px 22px 22px;
    }
    
    
}