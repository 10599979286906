.footer{
    background: $blue-arrow;
    @include max-mq(767px){
        text-align: center;
        padding: 110px 0;
    }

    @include max-mq(480px){
        padding: 50px 0;
    }
    .container{
        padding: 80px 15px 80px 0;
        @include max-mq(1920px){
            padding: 50px 15px 50px 0;
        }
        @include max-mq(1333px){
            padding: 50px 15px 20px;
        }
        @include max-mq(1200px){
            padding: 20px 15px;
        }
        @include max-mq(767px){
            padding: 40px 15px 30px;
        }
    }
   
    
    .container{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        @include max-mq(768px){
            justify-content: center;
        }
    }
    .logo-wrapper{
        width: 50%;
        @include max-mq(1200px){
            width: 30%;
            align-items: flex-start;
        }
        @include max-mq(767px){
            align-items: center;
            img{
                padding-bottom: 15px;
            }
        }

        .logo{
            @include max-mq(767px){
                max-width: 440px;
            }
        }
        .social{
            padding-left: 55px;
            @include max-mq(1200px){
                padding-left: 20px;
                padding-bottom: 20px;
                display: block;
            }
            @include max-mq(767px){
                padding-left: 0;
                padding-top: 20px;
            }
            a{
                padding: 0 5px;
                i{
                    font-size: 28px;
                    @include max-mq(1200px){
                        font-size: 20px;
                    }

                    @include max-mq(767px){
                        width: 84px;
                        line-height: 84px;
                        font-size: 40px;
                    }

                    @include max-mq(480px){
                        width: 55px;
                        line-height: 55px;
                        font-size: 35px;
                    }
                }
            }
        }
        @include max-mq(767px){
            flex-direction: column;
            width: 100%;
        }
    }
    .copyright{
        width: 50%;
        font-weight: 400;
        font-size: 16px;
        color: $main_white;
        display: flex;
        justify-content: flex-end;
        @include max-mq(1200px){
            width: 70%;
        }
        @include max-mq(767px){
            width: 100%;
            flex-direction: column;
            font-size: 24px;
            margin-top: 20px;
            p{
                margin-bottom: 0;
            }
        }
        .content{
            padding-left: 15px;
            display: flex;
            @include max-mq(1200px){
                align-items: flex-end;
                flex-direction: column;

            }
            @include max-mq(767px){
                padding-left: 0;
                width: 100%;
                align-items: center;
            }
        }
        ul{
            padding-left: 20px;
            @include max-mq(767px){
                padding-left: 0;
                margin-bottom: 0;
                margin-top: 10px;
            }
            li{
                list-style: none;
                display: inline-block;
                padding: 0 8px;
                position: relative;
                &:last-of-type{
                    padding-right: 0;
                    @include max-mq(767px){
                        padding-right: 8px;
                    }
                    @include max-mq(480px){
                        padding: 0 4px;
                    }
                }
                @include max-mq(480px){
                    padding: 0 4px;
                }
                &:after{
                    content: "";
                    position: absolute;
                    background: $footer-border;
                    height: 100%;
                    width: 1px;
                    top: 0;
                    bottom: 0;
                    right: -2px;
                    margin: auto;
                }
                &:last-of-type{
                    &:after{
                        display: none;
                    }
                }
                a{
                    color: $main_white;
                    &:hover{
                        color: $dark-blue;
                    }
                }
            }
        }
    }

    .lf-link{
        display:  flex;
        margin-top: 30px;
        justify-content: flex-end;
        width: 100%;
        font-size: 14px;
        @include max-mq(1200px){
            margin-top: 10px;
        }
        @include max-mq(767px){
            justify-content: center;
            margin-top: 30px;
        }
        a{
            color: $main_white;
            @include max-mq(767px){
                font-size: 13px;
            }
            &:hover{
                color: $dark-blue; 
            }
        }
    }
    

}