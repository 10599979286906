.single{
    @include max-mq(2560px){
        padding: 110px 0 120px;
    }
    @include max-mq(1920px){
        padding: 50px 0 120px;
    }
    @include max-mq(991px){
        padding: 50px 0;
    }
    @include max-mq(768px){
        padding: 20px 0 50px;
    }

    article{
        padding: 0 0 0 25px;
        @include max-mq(1440px){
            padding: 0;
        }
        h1{
            text-transform: none;
            font-size: 45px;
            font-weight: 600;
            color: $grey-question;
            @include max-mq(767px){
                font-size: 40px;
            }
            @include max-mq(580px){
                font-size: 35px;
                line-height: 1.2em;
            }
        }
        p{
            font-weight: 500;
            font-size: 18px;
            line-height: 30px;
            margin: 35px 0;
            color: $grey-paragraph;
            @include max-mq(768px){
                margin: 15px 0;
            } 
            @include max-mq(767px){
                font-size: 24px;
                line-height: 1.5em;
            }
        }
        ul{
            li{
                font-weight: 500;
                font-size: 18px;
                line-height: 30px;
                padding: 10px 0;
                color: $grey-paragraph;
                @include max-mq(767px){
                    font-size: 24px;
                }
            }
            
        }
        a{
            font-weight: 700;
            font-size: 18px;
            display: inline-block;
            margin-top: 10px;
            text-transform: capitalize;
            color: $blue-title;
            transition: all .4s;
            &:hover{
                color: $dark-blue;
            }
            @include max-mq(767px){
                font-size: 24px;
            }
            i{
                display: inline-block;
                padding-right: 10px;
                font-size: 16px;
                @include max-mq(767px){
                    font-size: 20px;
                }
            }
        }
    }
}