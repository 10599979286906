.store-locator-content-inner {
    padding-bottom: 85px;
    padding-top: 85px;
    background-image: url('/assets/images/arrow.jpg');
    @include max-mq( 640px ) {
        padding-bottom: 70px;
        padding-top: 70px;
    }
    #location-search-alert {
        margin-top: 0;
        text-align: center;
        color: $grey-paragraph;
        font-size: 45px;
        text-transform: uppercase;
    }
    .store-title {
        color: $light-blue;
        font-weight: 600;
    }
}
.store-locator-map-form-wrapper {
    display: flex;
    padding-bottom: 20px;
    @include max-mq( 767px ) {
        flex-wrap: wrap;
    }
    #locations-map {
        border: none;
        flex: 0 0 74%;
        height: 495px;
        margin: 0;
        max-width: 74%;
        width: 74%;
        @include max-mq( 1100px ) {
            height: 520px;
        }
        @include max-mq( 960px ) {
            flex: 0 0 67%;
            max-width: 67%;
        }
        @include max-mq( 767px ) {
            flex: 0 0 100%;
            max-width: 100%;
            height: 320px;
        }
        img {
            max-width: inherit !important;
        }
    }
    .map-form-wrap {
        display: flex;
        flex-wrap: wrap;
        flex: 0 0 26%;
        max-width: 26%;

        @include max-mq( 960px ) {
            flex: 0 0 33%;
            max-width: 33%;
        }
        @include max-mq( 767px ) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
    .map-form-heading {
        background-color: $light-blue;
        padding: 15px 20px;
        .map-form-title {
            margin: 0;
            color: $main_white;
        }
    }
    #store-user-location {
        flex: 0 0 100%;
        max-width: 100%;
    }
    .store-locator__faq {
        background-color: $light-blue;
        border-bottom: 1px solid $black;
        color: $main_white;
        margin: 0 0 -1px;
        padding: 15px 20px;
        flex: 0 0 100%;
        max-width: 100%;
        .store-locator-faq-title {
            margin-bottom: 15px;
            margin-top: 0;
        }
        a {
            color: $main_white;
            transition: all .5s;
            &:focus,
            &:hover {
                color: $dark-blue;
            }
        }
    }
    .form-input {
        position: relative;
    }
    .store-locator__faq,
    .form-content-wrapper {
        padding: 15px;
    }
    .form-content-wrapper {
        padding-bottom: 30px;
    }
    label {
        display: block;
        padding-bottom: 8px;
        padding-left: 4px;
        padding-top: 20px;
    }
    input,
    select {
        display: block;
        width: 100%;
        height: 44px;
        border-radius: 30px;
        text-indent: 10px;
        border: 1px solid $grey-chevron;
        max-width: 100%;
        &:focus,
        &:active {
            outline: none;
        }
    }
    input {
        line-height: 46px;
        padding: 0;
    }
    .store-search-btn {
        padding-top: 25px;
        .button {
            background-color: $light-blue;
            border-radius: 30px;
            border: 1px solid $light-blue;
            color: $main_white;
            cursor: pointer;
            height: 44px;
            
            transition: all .5s;
            width: 100%;
            &:hover {
                background-color: $main_white;
                color: $light-blue;
            }
        }
    }

    .infoWindow {
        h4 {
            font-size: 19px;
            margin: 15px 0;
        }
    }
    #address-messages {
        bottom: -17px;
        color: red;
        font-size: 13px;
        left: 14px;
        position: absolute;
    }
}
.clearfix {
    &:after {
        clear: both;
        content: "";
        display: block;
    }
}
.list-store-items {
    margin: 35px -10px 0;
    padding: 0;
    height: 452px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    @include max-mq( 767px ) {
        height: 292px;
    }
    .store-item {
        // float: left;
        padding: 15px 10px;
        flex: 0 0 25%;
        max-width: 25%;
        // width: 20%;
        @include max-mq( 767px ) {
            flex: 0 0 33.3333334%;
            max-width: 33.3333334%;
        }
        @include max-mq( 480px ) {
            flex: 0 0 50%;
            max-width: 50%;
        }
        @include max-mq( 350px ) {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .marker-image-icon-title-wrap {
        display: flex;
        align-items: center;
        .marker-image-icon-svg-wrap {
            width: 34px;
            height: 40px;
            background-image: url('../images/pin-ds.svg');
            background-size: cover;
            background-position: center;
        }
        .store-title {
            padding-left: 8px;
        }
    }
    .item-content.store-item-content {
        padding-top: 8px;
    }
    .store-title-heading {
        p {
            margin: 0;
        }
    }
}