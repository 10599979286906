$main-font-family: 'Hind Madurai', sans-serif;
$light-blue: #0175B7;
$dark-blue: #113F73;
$main_white: #fff;
$border-color: #748DA1;
$icons-bkg: #2490CF;
$grey-title: #474747;
$blue-title: #0276B8;
$blue-arrow: #0276B7;
$light-grey: #F3F5F7;
$footer-border: #6C8CB1;
$grey-question: #353738;
$grey-chevron: #727272;
$red: #D31313;
$blue-border: #93B8CE;
$light-blue-border: #93B8CE;
$menu-bkg: #0376B9;
$placeholder-color: #A1B0BC;
$grey-paragraph: #434343;
$span-color: #0D8DD5;
$white: #FEFEFE;
$black: #1A1919;
$light-grey-border: #EBEBEB;
$light-grey-bkg: #F5F5F5;
$light-blue-link: #2592D0;
$light-blue-decoration: #1F518B;
