.resources{
    background: url("../images/resources-hero-bkg.png");
    background-size: cover;
    background-position: center;
    padding-bottom: 170px;
    @include max-mq(1200px){
        padding-bottom: 100px;
    }
    @include max-mq(767px){
        padding: 150px 0;
    }
}

.article-section{
    padding: 75px 0 10px;
    @include max-mq(768px){
        padding-top: 50px;
    }
    .col-wrapper{
        display: flex;
        flex-wrap: wrap;
        position: relative;
        &:after{
            content: "";
            position: absolute;
            width: calc(100% - 30px);
            height: 1px;
            background: $light-blue-border;
            bottom: -35px;
            left: 0;
            right: 0;
            margin: auto;
        }
        .col-left{
            width: 50%;
            @include max-mq(768px){
                width: 100%;
            }
            .inner-wrapper{
                padding-right: 25px;
                padding-left: 10px;
                @include max-mq(1200px){
                    padding-left: 0;
                }
                @include max-mq(768px){
                    padding-right: 0;
                }
            }
        }
        .col-right{
            width: 50%;
            @include max-mq(768px){
                width: 100%;
            }
            .inner-wrapper{
                padding-left: 40px;
                @include max-mq(1200px){
                    padding-left: 0;
                    padding-right: 15px;
                }
                @include max-mq(768px){
                    padding-right: 0;
                }
    
            }
        }
        .single-article{
            margin-bottom: 40px;
            @include max-mq(768px){
                margin-bottom: 25px;
            }
            a{
                &:hover{
                    h3{
                        color: $dark-blue;
                    }
                }
                @include max-mq(767px){
                    display: flex;
                    align-items: center;
                }
            }
            h3{
                color: $blue-title;
                transition: all .4s;
                position: relative;
                display: inline-block;
                font-weight: 600;
                margin: 0;
                line-height: normal;
                @include max-mq(768px){
                    padding-right: 15px;
                }
                span{
                    color: $grey-question;
                    font-size: 24px;
                    font-weight: 700;
                    @include max-mq(480px){
                        font-size: 23px;
                    }
                }
                i{
                    // display: inline-block;
                    padding-left: 10px;
                    font-size: 16px;
                    width: 16px;
                    height: 16px;
                    position: absolute;
                    bottom: 13px;
                    line-height: 1;
                    @include max-mq(768px){
                        padding-left: 5px;
                        bottom: 12px;
                    }

                }
            }
            p{
                font-family: $main_font_family;
                font-weight: 500;
                font-size: 18px;
                line-height: 30px;
                color: $grey-paragraph;
                @include max-mq(767px){
                    font-size: 24px;
                    line-height: 1.5em;
                }
            }
        }
    }
    .article-pagination{
        margin: 65px auto 0;
        display: flex;
        justify-content: center;
    }

}

.simple-pagination li{
    padding-left: 15px;
    @include max-mq(768px){
        padding-left: 0;
    }
    &:first-of-type{
        padding-left: 0;
    }
}

body .light-theme a, .light-theme span{
    border-radius: 0;
    box-shadow: none;
    font-weight: 700 !important;
    font-family: $main_font_family;
    font-size: 15px;
    color: $grey-question;
    background: $main_white;
    border: 1px solid #EBEBEB !important;
    transition: all .4s;
    &:hover{
        color: $main_white;
        background: $blue-arrow;
    }
    @include max-mq(480px){
        font-size: 12px;
    }
}

.prev, .next{
    padding: 8px 30px !important;
    color: $grey-title !important;
    background: $main_white !important;
    border: 1px solid #EBEBEB !important;
    &:hover{
        color: $blue-arrow !important;
        
    }
    &.current{
        color: $blue-arrow !important;
    }
}

.light-theme .current{
    background: $blue-arrow;
    box-shadow: none;
    border-color: $blue-arrow;
    color: $main_white;
}

.page-link, .current{
    padding: 8px 16px !important;
    @include max-mq(480px){
        padding: 4px 8px !important;
    } 
}





