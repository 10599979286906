.quotes-section{
    background: url("../images/testimonials.jpg");
    background-size: cover;
    padding: 100px 0;
    @include max-mq(1024px){
        padding: 50px 0;
    }
    @include max-mq(767px){
        padding: 100px 0;
    }

    @include max-mq(480px){
        padding: 50px 0;
    }
    .quote-inner{
        max-width: 870px;
        margin: 0 auto;
        padding: 30px 0;
        text-align: center;
        @include max-mq(1024px){
            padding: 30px;
        }
        p{
            position: relative;
            font-weight: 500;
            font-size: 22px;
            color: $main_white;
            line-height: 1.4em;
            margin-bottom: 40px;
            @include max-mq(767px){
                font-size: 30px;
            }

            @include max-mq(480px){
                font-size: 25px;
            }
            &:before{
                content: "";
                background: url("../images/quotes.png");
                background-size: contain;
                width: 166px;
                height: 110px;
                position: absolute;
                left: -120px;
                top: -35px;
                z-index: -1;
                @include max-mq(1024px){
                    left: -50px;
                }
                @include max-mq(800px){
                    left: 0;
                    right: 0;
                    margin: auto;
                }
            }
        }
        h4{
            color: $main_white;
            font-size: 22px;
            @include max-mq(767px){
                font-size: 30px;
            }
            @include max-mq(480px){
                font-size: 25px;
            }
        }
    }
    .slick-dots{
        bottom: -10px;
        li {
            color: $main_white;
            &.slick-active{
                color: $main_white;
                button{
                    background: $light-blue;
                }
            }
            button{
                background: $main_white; 
                border-radius: 50%;
                width: 14px;
                height: 14px;
                &:before{
                    display: none;
                }
            }

        }
    }
}