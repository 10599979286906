.product-hero-section{
    background-position: center;
    min-height: 45vh;
    padding-top: 35px;

    @include max-mq(767px){
        min-height: 0;
        padding: 20px 0 240px;
    }

    @include max-mq(480px){
        padding: 20px 0 200px;
    }

    .img-wrapper{
        width: 100%;
        max-width: 50%;
        @include max-mq(767px){
            max-width: 100%;
        }
    }
    &.focus-fast{
        background: url("../images/hero-bkg.png");
        background-size: cover;
        background-position: center;
        .img-wrapper{
            z-index: 22;
            position: relative;   
        }
    }
    &.focus-fast-extreme{
        background: url("../images/focus-fast-extreme-hero.png");
        background-size: cover;
        background-position: center;
        .img-wrapper{
            z-index: 22;
            position: relative;
        }
    }
    &.focus-fast-kids{
        background: url("../images/focus-fast-kids.jpg");
        background-size: cover;
        background-position: center;
        .img-wrapper{
            z-index: 22;
            position: relative;
        }
    }

    .content{
        max-width: 50%;
        width: 100%;
        padding-top: 30px;
        @include max-mq(767px){
            max-width: 92%;
            margin: 0 auto;
            h1{
                font-size: 60px;
                line-height: 1.2em;
            }
        }
        @include max-mq(480px){
            max-width: 100%;
            h1{
                font-size: 45px;
            }
            
        }
    }
    .content-wrapper{
        @include max-mq(767px){
            flex-wrap: wrap;
        }
        padding: 30px 0;
       
        // @media screen and (max-height: 950px){
        //     max-height: 56vh;
        // }

        @include max-mq(1024px){
            padding: 40px 0 50px;
            min-height: 50vh;
            max-height: 100%;
        }
        

    
        img{
            position: absolute;
            top: -230px;
            min-height: 50vh;
            right: 0;
            @include max-mq(767px){
                left: 0;
                margin: auto;
                top: -10px;
                min-height: 716px;
            }

            @include max-mq(480px){
                max-height: 450px;
                min-height: 450px;
                top: 20px;
            }


        }
    }
    h1{
        color: $main_white;
        margin-top: 0px;
        margin-bottom: 20px;
        sup{
            font-size: 50px;
            top: 0;
            @include max-mq(480px){
                font-size: 45px;
            }
        }
    }
    ul{
        padding-left: 0;
        li{
            position: relative;
            list-style: none;
            padding-left: 50px;
            font-size: 30px;
            font-weight: 700;
            text-transform: uppercase;
            color: $main_white;
            padding-top: 15px;
            padding-bottom: 15px;
            display: block;
            @include max-mq(1440px){
                padding-left: 40px;
            }
            @include max-mq(768px){
                font-size: 25px;
                padding-left: 30px;
                line-height: 1;
            }
            @include max-mq(767px){
                font-size: 36px;
            }
            @include max-mq(480px){
                font-size: 30px;
            }
            @include max-mq(400px){
                font-size: 20px;
            }
            &:before{
                content: "\f061";
                position: absolute;
                background-repeat: no-repeat;
                height: 30px;
                width: 30px;
                left: 5px;
                top: 0;
                bottom: 0;
                margin: auto;
                color: $span-color;
                font-size: 30px;
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                line-height: 22px;
                @include max-mq(1440px){
                    left: 0;
                }
                @include max-mq(1024px){
                    top: 5px;
                }
                @include max-mq(991px){
                    bottom: initial;
                    top: 17px;
                    line-height: 1;
                    font-size: 24px;
                    width: 24px;
                    height: 24px;
                    left: 5px;
                }
                @include max-mq(768px){
                    font-size: 20px;
                    left: 0;
                }
                @include max-mq(767px){
                    font-size: 28px;
                }
                @include max-mq(480px){
                    font-size: 20px;
                    
                }
                @include max-mq(400px){
                    top: 15px;
                }
            }
            &:after{
                content: "";
                width: 460px;
                height: 3px;
                background: $border-color;
                position: absolute;
                left: 0;
                bottom: 0;
                @include max-mq(991px){
                    width: 100%;
                }
            }
            &:last-of-type{
                &:after{
                    display: none;
                }
            }
        }
    }
}