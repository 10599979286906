.page-hero{
    background: url("../images/information-hero.png");
    background-size: cover;
    background-position: center;
    padding: 100px 0;
    @include max-mq(767px){
        padding: 170px 0 250px;
    }
    @include max-mq(480px){
        padding: 100px 0;
    }
    .content-wrapper{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        color: $main_white;
        @include max-mq(768px){
            padding-left: 0;
        }
        @include max-mq(767px){
            max-width: 92%;
            width: 100%;
            margin: 0 auto;
        }
        h1{
            margin-top: 0;
            margin-bottom: 30px;
            text-transform: uppercase;
            @include max-mq(767px){
                font-size: 60px;
                margin-bottom: 50px;
            }
            @include max-mq(480px){
                font-size: 45px;
            }
        }
        p{
            font-size: 20px;
            font-weight: 500;
            margin-top: 0;
            line-height: 30px;
            width: 100%;
            @include max-mq(767px){
                margin-bottom: 0;
                font-size: 24px;
                line-height: 1.5em;
            }
        }
    }
}

.facts{
    background: url("../images/eye-bkg.png");
    background-position: top 180px left -310px;
    background-repeat: no-repeat;
    padding: 30px 0 50px;
    &.product-facts{
        padding: 60px 0 100px;
        @include max-mq(767px){
            padding: 105px 0;
            h2{
                margin-bottom: 0;
            }
        }
        @include max-mq(480px){
            padding: 50px 0;
        }
        .container{
            .content-right{
                .main-text{
                    margin-top: 65px;
                    @include max-mq(800px){
                        margin-top: 60px;
                    }
                    @include max-mq(767px){
                        margin-top: 25px;
                    }
                    p{
                        @include max-mq(767px){
                            font-size: 24px;
                            line-height: 1.55em;
                        }
                    }
                }
            }

        }
    }
    &.extreme-facts{
        background: url("../images/focus-fast-eye-extreme.png");
        background-repeat: no-repeat;
        background-position: top -40px left -300px;
    }

    &.kids-facts{
        background: url("../images/focus-fast-smiley.png");
        background-repeat: no-repeat;
        background-position: top -120px left -300px;

    }
    @include max-mq(768px){
        background-position: bottom right -60%;

    }
    @include max-mq(767px){
        background-position: center right;
    }
    @include max-mq(480px){
        background-position: top right 20%;
    }
    .container{
        display: flex;
        flex-wrap: wrap;
        @include max-mq(767px){
            flex-direction: column;
        }
        .content-left-wrapper, .content-right-wrapper{
            width: 50%;
            @include max-mq(767px){
                width: 92%;
            }
            @include max-mq(480px){
                width: 100%;
            }
        }
    
        .content-left, .content-right{
            h2{
                color: $grey-title;
                text-transform: uppercase;
            }
            p{
                margin-top: 0;
                font-size: 18px;
                font-weight: 400;
                @include max-mq(767px){
                    font-size: 24px;
                }
            }
            .main-text{
                margin-top: 55px;
                @include max-mq(768px){
                    margin-top: 20px;
                }
                p{
                    font-size: 18px;
                    font-weight: 400;
                    color: $grey-title;
                    line-height: 28px;
                    margin-top: 0;
                   @include max-mq(991px){
                       line-height: 24px;
                   }
                   @include max-mq(767px){
                       font-size: 24px;
                       line-height: 1.55em;
                   }
                }
            }
        }
        .content-left{
            padding: 0 45px 0 5px;
            @include max-mq(991px){
                padding-right: 20px;
            }
            p{
                color: $grey-title;
            }
            
            @include max-mq(767px){
                padding: 0;
            }
            .img-wrapper{
                position: relative;
                
                a{
                    img{
                        width: 100%;
                    }
                }
               
            }
            #lightbox{
                display: none;
                img{
                    max-height: 90vh !important;
                }
                
            }
            .centered-icon{
                background: rgba(2, 118, 183, .7);
                width: 106px;
                height: 106px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                margin: auto;
                cursor: pointer;
                transition: all .4s;
                &:hover{
                    background: rgba(2, 118, 183, .9);
                }

                @include max-mq(767px){
                    width: 185px;
                    height: 185px;
                    img{
                        min-height: 87px;
                    }
                }

                @include max-mq(480px){
                    width: 120px;
                    height: 120px;
                    img{
                        min-height: 0;
                    }
                }
            }
        }

        .content-right{
            padding: 0 10px;
            h2{
                @include max-mq(767px){
                    margin-top: 80px;
                    margin-bottom: 50px;
                }
                @include max-mq(480px){
                    margin-top: 50px;
                    margin-bottom: 30px;
                }
            }
            @include max-mq(767px){
                padding: 0;
            }
            .bold-text{
                color: $grey-question;
                @include max-mq(767px){
                    font-size: 24px;
                }
            }
        }
    }
}

.quality{
    &.science-quality{
        margin-top: 135px;
        padding-top: 200px;
        @include max-mq(767px){
            padding-top: 300px;
            padding-bottom: 170px;
            @include max-mq(580px){
                padding-top: 250px;
                padding-bottom: 100px;
            }
            @include max-mq(480px){
                padding-top: 280px;
            }
            @include max-mq(320px){
                padding-top: 400px;
            }
            .text-wrapper{
                max-width: 92%;
                margin: 0 auto;
                h2{
                    text-align: left;
                    margin-bottom: 15px;
                    @include max-mq(580px){
                        margin-top: 30px;
                    }
                }
            }
            
        }
    }
    background: url("../images/quality-bkg.jpg");
    background-size: cover;
    padding: 80px 0 100px;
    color: $main_white;
    background-position: right;
    @include max-mq(991px){
        padding: 50px 0;
    }
    @include max-mq(767px){
        background: url("../images/science-bkg-mobile.jpg");
        padding: 150px 0;
        background-position: center;
        background-size: cover;
    }

    @include max-mq(480px){
        padding: 100px 0;
    }
    p{
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 40px;
        @include max-mq(767px){
            font-size: 24px;
            line-height: 1.55em;
            margin-bottom: 20px;
        }
    }

    .text-wrapper{
        padding-left: 5px;
        @include max-mq(768px){
            padding: 0;
        }
        @include max-mq(767px){
            max-width: 92%;
            width: 100%;
            margin: 0 auto;
        }
        h2{
            text-align: center;
            text-transform: uppercase;
            @include max-mq(767px){
                margin: 5px 0 15px;
                text-align: left;
            }
        }
    }
    .full-width{
        width: 100%;
        padding: 5px 0;
    }

    .narrow-section{
        max-width: 700px;
        p{
            margin-top: 0;
        }
        .logos-wrapper{
            display: flex;
            justify-content: space-between;
            max-width: 635px;
            padding-top: 50px;
            padding-bottom: 50px;
            @include max-mq(991px){
                padding-top: 50px;
            }
            @include max-mq(768px){
                margin: 0 auto;
                padding-top: 50px;
                padding-bottom: 20px;
                img{
                    width: 20%;
                    height: 20%;
                }
            }
            @include max-mq(767px){
               padding-top: 30px;
               margin-top: 130px;
            }

            @include max-mq(480px){
                margin-top: 50px;
            }

            @include max-mq(400px){
                margin-top: 0;
                padding-bottom: 0;
            }

        }
    }
}

.faq-section{
    .faq-wrapper{
        padding: 60px 0 0;
        @include max-mq(768px){
            padding: 30px 0;
        }
        h2{
            color: $grey-title;
        }
    }
    .single-question-wrapper{
        background: $light-grey;
        cursor: pointer;
        .question{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            font-size: 30px;
            color: $grey-question;
            background: $light-grey;
            padding: 18px 20px 18px 30px;
            margin-bottom: 0;
            margin-top: 10px;
            @include max-mq(1200px){
                padding: 20px;
            }
            @include max-mq(480px){
                padding: 20px 10px;
            }
            @include max-mq(768px){
                font-size: 25px;
            }
            @include max-mq(767px){
                font-size: 30px;
            }
            @include max-mq(480px){
                font-size: 25px;
            }
            i{
                color: $grey-chevron;
                font-size: 20px;
                @include max-mq(768px){
                    font-size: 16px;
                }
                @include max-mq(767px){
                    font-size: 20px;
                }
            }
        }

        .answer{
            padding: 0 25px 15px 50px;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            margin-top: -20px;
            color: $grey-paragraph;
            p{
                &:first-of-type{
                    margin-top: 32px;
                    @include max-mq(768px){
                        margin-top: 15px;
                    }
                }
                @include max-mq(767px){
                    margin-top: 15px;
                    font-size: 24px;
                    line-height: 1.5em;
                }
            }
            @include max-mq(768px){
                padding: 0 30px 15px;
            }
            @include max-mq(767px){
                padding: 0 20px 15px;
            }
            ul{
                list-style: none;
                @include max-mq(480px){
                    padding-left: 20px;
                }
                li{
                    position: relative;
                    padding-left: 20px;
                    @include max-mq(767px){
                        font-size: 24px;
                        line-height: 1.5em;
                        padding-bottom: 10px;
                        padding-left: 15px;
                    }
                    &:before{
                        position: absolute;
                        content: "";
                        width: 6px;
                        height: 6px;
                        background: $dark-blue;
                        border-radius: 50%;
                        left: 0;
                        top: 9px;
                        @include max-mq(767px){
                            top: 14px;
                        }
                    }
                }
            }

            a{
                color: $light-blue;
                &:hover{
                    color: $dark-blue;
                }
            }
        }
        
    }
}

.featherlight-close{
    display: none;
}

.custombtn{
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 60px;
    color: $blue-arrow;
    cursor: pointer;
    transition: all .4s;
    @include max-mq(1920px){
        font-size: 40px;
    }
    @include max-mq(1440px){
        font-size: 60px;
    }

    @include max-mq(767px){
        top: 15px;
    }
    @include max-mq(580px){
        font-size: 35px;
        right: 5px;
    }
   
    &:hover{
        color: $dark-blue;
    }
}

.featherlight .featherlight-content{
    background: none;
    overflow: hidden;
    padding: 0;
    text-align: center;
    @include max-mq(1440px){
        overflow-y: visible;
        width: 70%;
    }
    @include max-mq(767px){
        width: 85%;
        padding-top: 15px;
    }
}

.featherlight-inner{
    text-align: center;
    img{
        max-height: 90vh;
        @include max-mq(1440px){
            max-height: none;
        }
    }
}
