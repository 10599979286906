.where-to-buy{
    padding: 35px 0 65px;
    @include max-mq(768px){
        padding-bottom: 100px;
        padding-top: 30px;
    }
    @include max-mq(767px){
        padding-top: 60px;
        padding-bottom: 120px;
    }
    @include max-mq(680px){
        padding-bottom: 75px;
    }

    .container{
        @include max-mq(767px){
            padding: 0;
        }
        
    }
    .info{
        text-align: center;
        padding: 0 15px;
        h2{
            text-transform: uppercase;
            color: $grey-question;
            margin-bottom: 20px;
            @include max-mq(767px){
                margin-bottom: 10px;
            }
        }
        p{
            font-weight: 500;
            font-size: 20px;
            margin-top: 0;
            margin-bottom: 0;
            color: $grey-paragraph;
            @include max-mq(767px){
                font-size: 24px;
                line-height: 34px;
                max-width: 450px;
                width: 100%;
                margin: 0 auto;
            }
        }
    }
    .content-wrapper{
        margin: 0 auto;
        padding: 0;
        display: flex;
        flex-direction: column;
        @include max-mq(767px){
            margin-top: -80px;
        }
        @include max-mq(600px){
            margin-top: -50px;
        }

        @include max-mq(320px){
            margin-top: 0;
        }
    }

    .img-wrapper{
        max-width: 1173px;
        width: 100%;
        position: relative;
        z-index: -1;
        margin-top: 10px;
        @include max-mq(1400px){
            max-width: 900px;
        }

        @include max-mq(1366px){
            margin-top: 0;
        }
        @include max-mq(1024px){
            position: static;
            
        }
        @include max-mq(800px){
            margin-bottom: 0px;
        }

        @include max-mq(767px){
            background: url('../images/focus-fast-hero-mobile.png');
            height: 700px;
            background-size: cover;
            background-position: center;
            img{
                display: none;
            }
        }

        @include max-mq(600px){
            min-height: 500px;
            height: 500px;
        }
        @include max-mq(480px){
            min-height: 400px;
            height: 400px;
        }

        @include max-mq(320px){
            min-height: 300px;
            height: 300px;
        }
    }
    .logos-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        text-align: center;
        padding: 0 15px;
        margin-top: -20px;
        @include max-mq(1024px){
            margin-top: 0;
        }
        @include max-mq(767px){
            margin-top: -60px;
        }
        legend{
            padding: 0 15px;
            margin: 0 auto;
            vertical-align: middle;
        }
        h4{
            color: $grey-paragraph;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 20px;
            letter-spacing: 0.15em;
            @include max-mq(767px){
                font-size: 24px;
            }
            @include max-mq(480px){
                letter-spacing: 0.07em;
            }
        }
        fieldset{
            width: 100%;
            border-color: $light-grey-border;
            display: flex;
            align-items: center;
            display: block;
            @include max-mq(767px){
                width: 90%;
                margin: 0 auto;
            }
        }
        .logo-content-wrapper{
            display: flex;
            justify-content: space-between;
            max-width: 820px;
            width: 100%;
            margin: 20px auto;
            @include max-mq(767px){
                flex-wrap: wrap;

            }
            @include max-mq(320px){
                margin-top: 0;
            }
            @include max-mq(520px){
                justify-content: center;
            }
        }
        a{
            max-width: 33.3333%;
            @include max-mq(767px){
                max-width: 100%;
                width: 100%;
                margin: 30px 0 65px;
                &:nth-of-type(1), &:nth-of-type(3){
                    img{
                        min-width: 220px;
                    }
                }
                &:nth-of-type(2){
                    img{
                        min-width: 360px;
                    }
                }
            }

            @include max-mq(480px){
                margin: 30px 0;
                &:nth-of-type(1), &:nth-of-type(3){
                    img{
                        min-width: 0;
                    }
                }
                &:nth-of-type(2){
                    img{
                        min-width: 0;
                    }
                }
            }
            
            img{
                display: inline-block;
            }
            
        }
        @include max-mq(991px){
            width: 100%;
        }
        
       
    }
}