.text-section{
    .container{
        padding: 50px 15px;
        @include max-mq(768px){
            padding: 30px 15px;
        }
    }
    h3, h4{
        color: $grey-question;
        @include max-mq(767px){
            margin: 15px 0;
        }
    }

    h3{
        @include max-mq(580px){
            font-size: 30px;
        }
    }

    h4{
        @include max-mq(767px){
            font-size: 25px;
        }
    }
    p{
        color: $grey-paragraph;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        @include max-mq(767px){
            font-size: 24px;
            line-height: 1.5em;
        }
    }
    a{
        color: $dark-blue;
        &:hover{
            color: $light-blue;
        }
        &:visited{
            color: $blue-arrow;
        }
    }
}



        