body{
    font-family: $main-font-family;
    box-sizing: border-box;
    &.menu-active {
        overflow-y: hidden;
    }
}
div{
    box-sizing: border-box;
}

.page-loader-wrapper{
    background: #fff;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.page-loader{
    display: block;
    width: 46px;
    height: 46px;
    margin: 1px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    border-radius: 50%;
    border: 5px solid #fff;
    border-color: $menu-bkg transparent $menu-bkg transparent;
    z-index: 9999;
    animation: lds-dual-ring 1.2s linear infinite;
  }
  
 @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

button:active, button:focus, input:focus {
    outline: none;
    border: none;
}

.container{
    max-width: 1200px;
    padding-left: 15px;
    padding-right: 15px; 
    margin: 0 auto;
}

.content-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

a{
    text-decoration: none;
    transition: all .4s;
}

img{
    max-width: 100%;
    height: auto;
}

p{
    @include max-mq(767px){
        font-size: 24px;
        line-height: 34px;
    }
}

h1{
    font-size: 50px;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 65px;
    span{
        font-size: 120px;
        position: relative;
        sup{
            font-size: 25px;
            position: absolute;
            top: 2em;
        }
    }
    @include max-mq(768px){
        font-size: 40px;
        line-height: 45px;
        span{
            font-size: 80px;
            sup{
                top: 1em;
            }
        }
    }
    @include max-mq(480px){
        font-size: 30px;
        line-height: 32px;
    }
}

h2{
    font-size: 45px;
    @include max-mq(991px){
        font-size: 35px;
    }
    @include max-mq(768px){
        font-size: 32px;
    }

    @include max-mq(767px){
        font-size: 60px;
    }
    @include max-mq(480px){
        margin: 20px 0;
        font-size: 45px;
    }
}

h3{
    font-size: 30px;
    font-weight: 700;
}

h4{
    font-size: 24px;
    font-weight: 700;
    @include max-mq(768px){
        font-size: 20px;
    }
}

h5{
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0;
}

.bold-text{
    font-weight: 700;
    font-size: 20px;
    @include max-mq(767px){
        font-size: 24px;
    }
}

.red-text{
    font-weight: 700;
    font-size: 20px;
    color: $red;
    @include max-mq(767px){
        font-size: 24px;
        line-height: 1.55em;
    }
}


sup{
    font-size: 15px;
    top: -0.2em;
}

.error-page{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
}

.error-section{
    color: $grey-title;
    padding: 30px 0;
    @include max-mq(767px){
        text-align: center;
    }
}

.error-message{
    display: flex;
    align-items: center;
    @include max-mq(767px){
        flex-direction: column;
    }
    h2{
        color: $grey-title;
        @include max-mq(767px){
            margin-top: 10px;
        }
    }
}
form {
    .error {
        bottom: -25px;
        left: 33px;
    }
}

.arrow-toggle{
    color: $dark-blue;
    position: relative;
    i{
        @include max-mq(400px){
            font-size: 30px;
        }
        position: absolute;
        right: -250px;
        line-height: 29px;
        @include max-mq(992px){
            line-height: 20px;
        }
        @include max-mq(768px){
            right: -200px;
        }
    }
}