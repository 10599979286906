.hero-section{
    .hero-wrapper-outer{
        max-width: 1200px;
        padding: 0 15px;
        @include max-mq(1660px){
            position: relative;
        }
        @include max-mq(767px){
            padding: 0 15px 30px;
            margin: 0 auto;
        }

        .content-wrapper{
            @include max-mq(1660px){
                max-width: 400px;
            }
            @include max-mq(1440px){
                max-width: 350px;
            }
            @include max-mq(1024px){
                max-width: 100%;
            }
        }
    }
    background-image: url("../images/focus-fast-hero.jpg");
    background-position: center;
    background-size: cover;
    display: flex;
    min-height: 82vh;
    @include max-mq(1660px){
        min-height: 0;
        height: auto;
    }
    @include max-mq(1200px){
        height: auto;
        padding: 15px 0; 
    }
    @include max-mq(1024px){
        background-image: url("../images/home-hero-mobile.png");
        background-repeat: no-repeat;
        padding: 50px 0 650px;
        position: relative;
        z-index: 1;
        background-position: center;
        
    }

    @include max-mq(767px){
        min-height: 0;
        padding: 100px 0 500px;
        background-image: url("../images/ff-hero-mobile-bkg.jpg");
    }

    @include max-mq(680px){
        padding: 50px 0 350px;
    }
    @include max-mq(580px){
        padding: 50px 0 200px;
    }

    @include max-mq(400px){
        padding: 35px 0 90px;
    }
    
    .main-title{
        display: none;
        @include max-mq(768px){
            display: block;
            position: relative;
            top: 70px;
            h1{
                color: $main_white;
                margin-top: 25px;
                span{
                    color: $span-color;
                }
            }
        }
        @include max-mq(480px){
            top: 30px;
        }
    }
    .img-wrapper{
        width: 100%;
        @include max-mq(768px){
            width: 45%;
        }
        img{
            position: relative;
            top: 140px;
            left: -5px;
            max-height: 80vh;
            @include max-mq(1200px){
                max-height: 760px;
                left: 0;
                top: 170px;
            }
            @include max-mq(991px){
                max-height: 650px;
            }
            @include max-mq(768px){
                top: 160px;
            }
            @include max-mq(580px){
                top: 100px;
            }
            @include max-mq(400px){
                top: 80px;
            }
        }
    }
    .content{
        width: 100%;
        padding-top: 210px;
        @include max-mq(1660px){
            padding-top: 150px;
            padding-bottom: 150px;
        }

        @include max-mq(1200px){
            padding: 50px 0;
        }

        @include max-mq(1024px){
            padding-bottom: 0;
        }


        @include max-mq(767px){
            padding-top: 0px;
            padding-bottom: 0;
        }

        @include max-mq(580px){
            padding-bottom: 100px;
        }

        @include max-mq(480px){
            padding-bottom: 80px;
        }

        img{
            @include max-mq(1330px){
                max-width: 300px;
            }
            @include max-mq(1200px){
                max-width: 280px;
            }
            @include max-mq(1024px){
                max-width: 100%;
            }
            @include max-mq(680px){
                max-width: 450px;
            }
            @include max-mq(580px){
                max-width: 300px;
            }

            @include max-mq(480px){
                max-width: 250px;
            }

            @include max-mq(375px){
                max-width: 200px;
            }
        }
       
        h1{
            color: $main_white;
            margin-top: 25px;
            @include max-mq(768px){
                display: none;
            }
            span{
                color: $span-color;
            }
            @include max-mq(991px){
                font-size: 45px;
                line-height: 50px;
            }
        }
        ul{
            padding-left: 30px;
            margin-top: 30px;
            @include max-mq(767px){
                padding-left: 0px;
                margin-top: 60px;
            }
            @include max-mq(580px){
                margin-top: 20px;
            }
            @include max-mq(480px){
                margin-top: 10px;
            }
            li{
                position: relative;
                list-style: none;
                padding-left: 50px;
                font-size: 30px;
                font-weight: 700;
                text-transform: uppercase;
                color: $main_white;
                padding-top: 15px;
                padding-bottom: 15px;
                @include max-mq(1440px){
                    padding-left: 40px;
                    font-size: 25px;
                }
                @include max-mq(768px){
                    font-size: 36px;
                    padding-left: 35px;
                    line-height: 1;
                }
                // @include max-mq(767px){
                //     font-size: 36px;
                //     padding-left: 35px;
                // }

                @include max-mq(580px){
                    font-size: 25px;
                }
                @include max-mq(480px){
                    font-size: 20px;
                }
                @include max-mq(400px){
                    font-size: 18px;
                    padding-left: 25px;
                }
                &:before{
                    content: "\f061";
                    position: absolute;
                    background-repeat: no-repeat;
                    height: 30px;
                    width: 30px;
                    left: 5px;
                    top: 0;
                    bottom: 0;
                    margin: auto;
                    color: $span-color;
                    font-size: 30px;
                    font-family: "Font Awesome 5 Free";
                    font-weight: 900;
                    line-height: 22px;
                    @include max-mq(1440px){
                        left: 0;
                    }
                    @include max-mq(1024px){
                        top: 5px;
                    }
                    @include max-mq(991px){
                        bottom: initial;
                        top: 17px;
                        line-height: 1;
                        font-size: 24px;
                        width: 24px;
                        height: 24px;
                        left: 5px;
                    }
                    @include max-mq(768px){
                        font-size: 28px;
                        left: 0;
                    }
                    @include max-mq(767px){
                        font-size: 28px;
                        left: 0px;
                    }

                    @include max-mq(480px){
                        font-size: 20px;
                        top: 15px;
                    }
                    @include max-mq(400px){
                        font-size: 18px;
                        top: 13px;
                    }
                    
                }
                &:after{
                    content: "";
                    width: 460px;
                    height: 3px;
                    background: $border-color;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    @include max-mq(768px){
                        width: 100%;
                    }
                }
                &:last-of-type{
                    &:after{
                        display: none;
                    }
                }
            }
        }
    }
}

//Mailchimp form
#mce-EMAIL-error {
    position: absolute;
    color: $red;
}
#emailUser-error {
    bottom: -50px;
}