.active{
    color: $dark-blue;
}

.hamburger{
    display: none;
    @include max-mq(1024px){
        z-index: 100;
        display: block;
    }

    .hamburger-box{
        @include max-mq(767px){
            width: 60px;
            height: 40px;
        }
    }
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after, 
.hamburger.is-active .hamburger-inner, .hamburger.is-active .hamburger-inner:after, .hamburger.is-active .hamburger-inner:before{
    background-color: $main_white;
}

.hamburger-inner::before{
    width: 35px;
    right: 0;
    height:5px;
    @include max-mq(767px){
        width: 47px;
        height: 7px;
        top: -12px;
    }

}

.hamburger--collapse .hamburger-inner::after{
    right: 0;
    height:5px;
    @include max-mq(767px){
        width: 57px;
        height: 7px;
        top: -25px;
    }
}

.hamburger-inner{
    width: 30px;
    height:5px;
    @include max-mq(767px){
        width: 37px;
        height: 7px;
    }
}

.hamburger.is-active .hamburger-inner:before, .hamburger.is-active .hamburger-inner{
    width: 35px;
    height: 7px;
    @include max-mq(767px){
        width: 50px;
        height: 7px;
    }
}

.hidden-links{
    display: none;
}

.navigation-links{
    position: relative;
    right: 230px;
    @include max-mq(1024px){
        display: none;
    }
}

.sub-menu{
    &.opened{
        display: block;
    }
    display: flex;
    flex-direction: column;
    position: absolute;
    padding-left: 0;
    width: 203px;
    left: -26px;
    top: 61px;
    background: $dark-blue;
    z-index: 20;
    display: none;
    transition: .4s all;

    @include max-mq(1200px){
        top: 86px;
    }
    @include max-mq(1024px){
        position: relative;
        top: 0;
        left: 0;
        background: transparent;
        width: auto;
        transition: unset;
    }
    &:after{
        content: "";
        background: transparent;
        position: absolute;
        top: -40px;
        width: 100%;
        height: 100%;
        left: 0;
        @include max-mq(1024px){
            display: none;
        }
        
    }
    li{
        padding: 20px;
        position: relative;
        display: block;
        z-index: 30;
        @include max-mq(1024px){
            &:last-of-type{
                padding-bottom: 0;
            }
        }
        &:after{
            content: "";
            width: calc(100% - 20px);
            height: 1px;
            background: $light-blue-decoration;
            position: absolute;
            bottom: -60px;
            left: 0;
            right: 0;
            margin: auto;
            z-index: 30;
        }
        a{
            color: $main_white;
            text-transform: capitalize;
            &:hover{
                color: $light-blue-link;
                @include max-mq(1024px){
                    color: $dark-blue;
                }
            }
            &.active{
                color: $light-blue-link;
                @include max-mq(1024px){
                    color: $dark-blue;
                }
            }
        }
        
    }
    &:before{
        content: "";
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-bottom: 12px solid $dark-blue;
        position: absolute;
        top: -10px;
        left: 17px;
        @include max-mq(1024px){
            display: none;
        }
    }

    
}

.menu-has-children{
    &:after{
        // margin-left: 85%;
    }
   &.expandeds{
        > a{
            color: $dark-blue;
        }
        i{
    
            &:before{
                content: "\f077";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
            }
        }
        
    }

    &.active-item{
        > a{
            color: $dark-blue;
        }
    }
}

header.sticky{
    .sub-menu{
        top: 49px;
        @include max-mq(1024px){
            top: 0;
        }
    }
    
}    

@include max-mq(1024px) {
    .navigation-links{
        right: 0;
        padding-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        ul{
            padding-left: 0;
            li{
                display: block;
                text-align: center;
                padding: 20px 0;
                @include max-mq(480px){
                    padding: 15px;
                }
                &:after{
                    display: none;
                }
                a{
                    font-size: 26px;
                    transition: all .4s;
                    @include max-mq(991px){
                        font-size: 20px;
                    }
                }
            }
        }
        .social i{
            font-size: 24px;
        }
        .blue-btn-hidden{
            display: block;
            @include max-mq(750px){
                font-size: 18px;
            }
        }
    }
    #site-navigation {
      position: relative;
      &.anim-top {
  
        > div {
            background-color: $menu-bkg;
            height: 100%;
            position: fixed;
            transition: all .5s;
            width: 100%;
            z-index: 99;
            top: -100%;
            padding-top: 50px;
            overflow: auto;
            @include max-mq(850px){
                height: 100%;
                top: -200%;
            }
            @include max-mq(580px){
                height: 100%;
            }
            @include max-mq(480px){
                padding-top: 15px;
            }
          }
  
        &.toggled > div{
            opacity: 1;
            top: 0%;
            visibility: visible;
        }
      }
    }
  .menu-toggle {
    display: none;
    background-color: transparent;
    border: none;
    font-size: 28px;
    padding: 0;
  
    @include max-mq(1024px) {
      display: block !important;
    }
  }

  .toggled{
      .navigation-links{
          .hidden-links{
              display: block;
          }
      }
  }

}
