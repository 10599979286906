.blue-btn{
    background-color: $dark-blue;
}

.back-to-home{
    border: 1px solid $light-blue;
    padding: 10px 20px;
    font-size: 35px;
    font-weight: 700;
    color: $light-blue;
    margin-left: 20px;
    @include max-mq(767px){
        margin-left: 0;
    }
    &:hover{
        color: $main_white;
        background: $light-blue;
    }
}