.features{
    @include max-mq(1660px){
        position: relative;
    }
    &.product-features{
        margin-top: 0;
        background: $light-grey-bkg;
        padding: 50px 0;
        @include max-mq(767px){
            padding-top: 390px;
        }
        @include max-mq(480px){
            padding-top: 200px;
        }
        .feature-wrapper{
            .single-feature{
                &:nth-of-type(1){
                    .feature-image{
                        background: url("../images/school.jpg");
                        background-size: cover;
                    }
                }
                &:nth-of-type(2){
                    .feature-image{
                        background: url("../images/work.jpg");
                        background-size: cover;
                    }
                }
                &:nth-of-type(3){
                    .feature-image{
                        background: url("../images/home.jpg");
                        background-size: cover;
                    }
                }
            }
        }
        &.extreme{
            .feature-wrapper{
                .single-feature{
                    &:nth-of-type(1){
                        .feature-image{
                            background: url("../images/fitness.jpg");
                            background-size: cover;
                        }
                    }
                    &:nth-of-type(2){
                        .feature-image{
                            background: url("../images/gamers.jpg");
                            background-size: cover;
                        }
                    }
                    &:nth-of-type(3){
                        .feature-image{
                            background: url("../images/extreme.jpg");
                            background-size: cover;
                        }
                    }
                }
            }
        }

        &.kids-features{
            .feature-wrapper{
                .single-feature{
                    
                    &:nth-of-type(1){
                        .feature-image{
                            background: url("../images/school-kids.jpg");
                            background-size: cover;
                        }
                    }
                    &:nth-of-type(2){
                        .feature-image{
                            background: url("../images/play.jpg");
                            background-size: cover;
                        }
                    }
                    &:nth-of-type(3){
                        .feature-image{
                            background: url("../images/home-kids.jpg");
                            background-size: cover;
                        }
                    }
                }
            }
        }
        
        .subtitle-content{
            padding: 50px 0 15px;
            @include max-mq(1024px){
                padding-top: 0;
            }
            @include max-mq(767px){
                padding: 0 30px;
                margin-bottom: 80px;
            }
            @include max-mq(480px){
                padding: 0 15px;
                margin-bottom: 30px;
                margin-top: 30px;
            }
            p{
                font-weight: 700;
                font-size: 20px;
                line-height: 1.3em;
                @include max-mq(767px){
                    font-size: 24px;
                    line-height: 1.5em;
                }
                sup{
                    font-size: 22px;
                    top: 0;
                }
            }
        }
        
    }
    padding: 0px;
    margin-top: -80px;
    @include max-mq(1024px){
        padding-top: 80px;
        margin-top: 0;
    }
    @include max-mq(767px){
        margin-top: -150px;
        position: relative;
        z-index: 20;
    }
    @include max-mq(580px){
        margin-top: 0;
        padding-top: 50px;
    }
    .feature-wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .single-feature{
            flex: 0 0 32.33333334%;
            max-width: 32.33333334%;
            padding-left: 15px;
            padding-right: 15px;
            margin-bottom: 15px;
            @include max-mq(800px){
                margin-bottom: 25px;
            }
            @include max-mq(767px){
                margin-bottom: 50px;
            }
            @include max-mq(480px){
                margin-bottom: 30px;
            }
            &:nth-of-type(1){
                .feature-image{
                    background: url("../images/work.jpg");
                }
            }
            &:nth-of-type(2){
                .feature-image{
                    background: url("../images/home.jpg");
                }
            }
             &:nth-of-type(3){
                .feature-image{
                    background: url("../images/school.jpg");
                }
            }
             &:nth-of-type(4){
                margin-bottom: 0;
                @include max-mq(991px){
                    margin-bottom: 15px;
                }
                @include max-mq(800px){
                    margin-bottom: 25px;
                }
                @include max-mq(767px){
                    margin-bottom: 50px;
                }
                @include max-mq(480px){
                    margin-bottom: 30px;
                }
                .feature-image{
                    background: url("../images/gamers.jpg");  
                }
                
            }
             &:nth-of-type(5){
                margin-bottom: 0;
                @include max-mq(991px){
                    margin-bottom: 15px;
                }
                @include max-mq(800px){
                    margin-bottom: 25px;
                }
                @include max-mq(767px){
                    margin-bottom: 50px;
                }
                @include max-mq(480px){
                    margin-bottom: 30px;
                }
                .feature-image{
                    background: url("../images/extreme.jpg");
                }
            }
             &:nth-of-type(6){
                margin-bottom: 0;
                @include max-mq(991px){
                    margin-bottom: 15px;
                }
                @include max-mq(800px){
                    margin-bottom: 25px;
                }
                @include max-mq(767px){
                    margin-bottom: 50px;
                }
                @include max-mq(480px){
                    margin-bottom: 30px;
                }
                .feature-image{
                    background: url("../images/fitness.jpg");
                    background-position: center;
                    
                }
            }
            @include max-mq(991px) {
                flex: 0 0 50%;
                max-width: 50%;
            }
            @include max-mq(767px) {
                flex: 0 0 100%;
                margin-bottom: 50px;
                max-width: 100%;
                position: relative;
                width: 100%; 
                &:last-of-type{
                    margin-bottom: 50px;
                }
            }

            @include max-mq(480px){
                margin-bottom: 30px;
                &:last-of-type{
                    margin-bottom: 0;
                }
            }
            
            .inner-wrapper{
                // display: flex;
                display: block;
                text-align: center;
                @include max-mq(768px){
                    align-items: center;
                    justify-content: space-around;
                }
                @include max-mq(480px){
                    padding: 0;
                }
                .feature-heading {
                    text-align: center;
                }
                .feature-image{
                    text-align: center;
                    background-size: cover;
                    width: 277px;
                    height: 277px;
                    margin: 0 auto;
                    border-radius: 50%;
                    border: 5px solid white;
                    -webkit-box-shadow: 1px 1px 15px 4px rgba(0, 0, 0, 0.12);
                    -moz-box-shadow: 1px 1px 15px 4px rgba(0, 0, 0, 0.12);
                    box-shadow: 1px 1px 15px 4px rgba(0, 0, 0, 0.12);
                    @include max-mq(767px){
                        width: 360px;
                        height: 360px;
                    }

                    @include max-mq(600px){
                        width: 237px;
                        height: 237px;
                    }

        
                   
                    // @include max-mq(480px){
                    //     width: 40%;
                    // }
                }
                .feature-content{
                    // max-width: 300px;
                    // padding-left: 34px;
                    @include max-mq(1200px){
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                    @include max-mq(1024px){
                       padding-left: 0;
                       padding-right: 0;
                    }

                    @include max-mq(767px){
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                
                    h3{
                        margin-bottom: 15px;
                        margin-top: 20px;
                        font-size: 30px;
                        color: $grey-paragraph;
                        text-transform: uppercase;
                        @include max-mq(767px){
                            font-size: 36px;
                            line-height: 46px;
                            margin-top: 30px;
                        }
                    }
                    p{
                        font-family: $main-font-family;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 28px;
                        margin-top: 0;
                        color: $grey-paragraph;
                        @include max-mq(767px){
                            font-size: 24px;
                            line-height: 34px;
                            br{
                                display: none;
                            }
                        }
                        @include max-mq(480px){
                            font-size: 20px;
                            line-height: 1.5em;
                        }
                    }
                }
            }
            
        }
        .reverse{
            .inner-wrapper{
                flex-direction: row-reverse;
                text-align: right;
                .feature-content{
                    padding-right: 25px;
                    padding-left: 0;
                    @include max-mq(1200px){
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                    @include max-mq(1024px){
                        // padding-left: 0;
                        // padding-right: 0;
                    }
                    @include max-mq(480px){
                        padding-left: 0;
                    }
                }
            }
            
        }
    }
}



