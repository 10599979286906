.newsletter{
    background: url("../images/newsletter-bkg.png");
    background-size: cover;
    background-position: center right;
    padding: 50px 0 0px;
    @include max-mq(767px){
        padding: 100px 35px 0;
    }

    @include max-mq(480px){
        padding: 50px 0px 0;
    }

    .container{
        display: flex;
        flex-wrap: wrap;
        @include max-mq(767px){
            flex-direction: column-reverse;
        }
    }
    .half-width-section-with-bkg{
        background: url("../images/brain.png");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: top right 25px;
        width: 40%;
        padding: 170px 0;
        @include max-mq(991px){
            background-position: top right; 
            width: 46%;
        }
        @include max-mq(767px){
            width: 100%;
            max-width: 440px;
            margin-left: 60px;
            background-size: 100%;
            // padding: 360px 0 0;
            background-repeat: no-repeat;
        }

        @include max-mq(600px){
            margin: 0 auto;
        }
        @include max-mq(480px){
            max-width: 340px;
            background-position: bottom;
        }
        // @include max-mq(400px){
        //     background-position: top right 40px;
        // }
        // @include max-mq(320px){
        //     background-position: top right 0;
        // }
    }
    .half-width-section{
        width: 50%;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        @include max-mq(767px){
            width: 100%;
            margin: 0;
        }
        .text-content{
            padding-left: 10px;
            @include max-mq(767px){
                margin-bottom: 30px;
                padding-left: 0;
            }
            h2{
                font-weight: 300;
                margin: 35px 0 10px;
                color: $black;
                @include max-mq(767px){
                    margin-top: 20px;
                    margin-bottom: 20px;
                }
            }
            p{
                font-weight: 400;
                font-size: 18px;
                margin-top: 0;
                line-height: 25px;
                color: $grey-title;
                @include max-mq(767px){
                    font-size: 24px;
                    line-height: 34px;
                }
            }
        }
    }
    form{
        padding-bottom: 15px;
        .field-wrapper{
            display: flex;
            margin-top: 35px;
            margin-bottom: 50px;
            position: relative;
            @include max-mq(1250px){
                max-width: 100%;
            }
            @include max-mq(767px){
                margin-bottom: 0;
            }
            input{
                border: none;
                border-top-left-radius: 50px;
                border-bottom-left-radius: 50px;
                font-size: 18px;
                font-weight: 400;
                font-family: $main_font_family;
                width: 70%;
                padding: 35px 0 35px 35px;
                @include max-mq(991px){
                    width: 60%;
                }
                @include max-mq(768px){
                    padding-left: 20px;
                    font-size: 16px;
                }
                @include max-mq(767px){
                    font-size: 24px;
                    padding-left: 45px;
                    width: 70%;
                }
                @include max-mq(580px){
                    width: 60%;
                    padding-left: 20px;
                    font-size: 20px;
                }
                ::-webkit-input-placeholder { 
                    font-size: 18px;
                    font-weight: 400;
                    font-family: $main_font_family;
                    color: $placeholder-color !important;
                    @include max-mq(768px){
                        font-size: 16px;
                    }
                    @include max-mq(767px){
                        font-size: 24px;
                    }
                    @include max-mq(480px){
                        font-size: 20px;
                    }
                  }
                  ::-moz-placeholder { 
                    font-size: 18px;
                    font-weight: 400;
                    font-family: $main_font_family;
                    color: $placeholder-color !important;
                    @include max-mq(768px){
                        font-size: 16px;
                    }
                    @include max-mq(767px){
                        font-size: 24px;
                    }
                    @include max-mq(480px){
                        font-size: 20px;
                    }
                  }
                  :-ms-input-placeholder { 
                    font-size: 18px;
                    font-weight: 400;
                    font-family: $main_font_family;
                    color: $placeholder-color !important;
                    @include max-mq(768px){
                        font-size: 16px;
                    }
                    @include max-mq(767px){
                        font-size: 24px;
                    }
                    @include max-mq(480px){
                        font-size: 20px;
                    }
                  }
                  :-moz-placeholder { 
                    font-size: 18px;
                    font-weight: 400;
                    font-family: $main_font_family;
                    color: $placeholder-color !important;
                    @include max-mq(768px){
                        font-size: 16px;
                    }
                    @include max-mq(767px){
                        font-size: 24px;
                    }
                    @include max-mq(480px){
                        font-size: 20px;
                    }
                }

            }
            
            button{
                width: 35%;
                border-top-right-radius: 50px;
                border-bottom-right-radius: 50px;
                border: 1px solid $light-blue;
                background: $light-blue;
                color: $main_white;
                font-weight: 500;
                font-size: 24px;
                transition: all .4s !important;
                @include max-mq(991px){
                    font-size: 20px;
                    width: 40%;
                }
                @include max-mq(767px){
                    width: 30%;
                    font-size: 24px;
                }
                @include max-mq(580px){
                    font-size: 20px;
                    width: 40%;
                }
                &:hover{
                    background: $main_white;
                    color: $light-blue;
                }
                i{
                    font-size: 15px;
                    display: inline-block;
                    position: relative;
                    left: 10px;
                    @include max-mq(991px){
                        left: 5px;
                        font-size: 12px;
                    }
                    @include max-mq(767px){
                        font-size: 20px;
                    }
                    @include max-mq(480px){
                        left: 0;
                        font-size: 18px;
                    }
                }
            }
        }
    }
}

#emailUser-error{
    position: absolute;
    bottom: -30px;
    left: 10px;
    color: red;
    font-size: 14px;
}

#hiddenRecaptcha-error{
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
}