.section-with-bkg{
    h2{
        color: $grey-title;
        text-transform: uppercase;
        text-align: center;
    }
    .testimonials{
        display: flex;
        align-items: center;
    }
    .testimonial-wrapper{
        background: $main_white;
        border-radius: 120px;
        @include max-mq(991px){
            border-radius: 50px;
        }
        @include max-mq(768px){
            margin-bottom: 0;
        }
        @include max-mq(480px){
            border-radius: 40px;
        }
        .hidden-content{
            display: none;
            @include max-mq(768px){
                display: flex;
                flex-direction: column;
                width: 100%;
                h5{
                    color: $blue-title;
                    @include max-mq(400px){
                        font-size: 14px;
                    }
                }
                img{
                    display: block;
                    padding-top: 10px;
                    padding-left: 10px;
                    height: 20px;
                    border-radius: 0;
                    @include max-mq(400px){
                        height: 15px;
                    }
                }
            }
        }
        .wrapper-inner{
            display: flex;
            padding: 30px 40px;
            flex-wrap: wrap;
            align-items: center;
            cursor: pointer;
            @include max-mq(1200px){
                align-items: flex-start;
            }
            
            @include max-mq(991px){
                padding: 30px 20px;
            }
            @include max-mq(580px){
                padding: 30px 10px;
            }
            .img-wrapper{
                img{
                    border-radius: 50%;
                }
                @include max-mq(991px){
                    width: 20%;
                }
                @include max-mq(768px){
                    width: 23%;
                }
            }
            .testimonial-content{
                max-width: 890px;
                margin: 0 auto;
                @include max-mq(1333px){
                    max-width: none;
                    width: 80%;
                }
                @include max-mq(1200px){
                    padding-left: 20px;
                }
                @include max-mq(768px){
                    width: 75%;
                    padding-left: 0;
                }
                @include max-mq(680px){
                    padding-left: 20px;
                }
                @include max-mq(420px){
                    padding-left: 15px;
                }
                h5{
                    display: flex;
                    align-items: center;
                    color: $blue-title;
                    @include max-mq(768px){
                        display: none;
                    }
                    img{
                        padding-left: 25px;
                        display: inline-block;
                        border-radius: 0 !important;
                        height: 20px;
                        @include max-mq(991px){
                            height: 20px;
                            padding-left: 15px;
                        }
                    }
                }
                p{
                    font-weight: 400;
                    font-size: 20px;
                    margin-top: 0;
                    line-height: 30px;
                    color: $grey-title;
                    @include max-mq(768px){
                        font-size: 18px;
                        line-height: 28px;
                    }
                    @include max-mq(480px){
                        font-size: 16px;
                        line-height: 26px;
                    }
                }
            }
            &.no-img{
                .testimonial-content{
                    width: 100%;
                    max-width: none;
                    padding: 15px 0 15px 15px;
                    @include max-mq(991px){
                        padding: 0 10px;
                    }
                } 
            }
        }
    }

    .dark-blue-bkg{
        background: url("../images/dark-blue-bkg.png");
        background-size: cover;
        padding: 150px 0 100px;
        background-position: center top;
        @include max-mq(800px){
            margin-top: -70px;
        }
        @include max-mq(767px){
            padding-top: 260px;
            margin-top: -80px;
        }
        @include max-mq(480px){
            margin-top: -180px;
            padding-top: 300px;
        }
        // @include max-mq(400px){
        //     margin-top: -250px;
        // }
        h2{
            color: $white;
            margin-bottom: 0;
            margin-top: 60px;
        }
        .two-col-section{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            color: $main-white;
            @include max-mq(768px){
                flex-direction: column;
                justify-content: center;
            }
            .content-left{
                width: 50%;
                @include max-mq(1200px){
                    width: 55%;
                }
                @include max-mq(768px){
                    width: 100%;
                }
                ul{
                    list-style: none;
                    padding-top: 25px;
                    padding-left: 15px;
                    @include max-mq(768px){
                        padding-left: 35px; 
                        padding-top: 20px;
                    }
                    @include max-mq(767px){
                        padding-right: 20px;
                        padding-left: 45px;
                    }
                    @include max-mq(480px){
                        padding-left: 15px;
                    }
                    @include max-mq(360px){
                        padding-right: 0;
                    }
                    li{
                        font-size: 20px;
                        font-weight: 500;
                        position: relative;
                        padding: 15px 15px 25px 40px;
                        line-height: 30px;
                        @include max-mq(991px){
                            font-size: 18px;
                            line-height: 28px;
                        }
                        @include max-mq(768px){
                            padding-left: 35px;
                        }
                        @include max-mq(767px){
                            font-size: 24px;
                            padding-left: 60px;
                            line-height: 35px;
                        }
                        @include max-mq(480px){
                            padding-left: 50px;
                        }
                        &:after{
                            content: "";
                            position: absolute;
                            background: url("../images/checkmark.png");
                            background-repeat: no-repeat;
                            width: 40px;
                            height: 40px;
                            top: 30px;
                            left: -15px;
                            bottom: 0;
                            margin: 0 auto;
                            background-size: contain;
                            @include max-mq(991px){
                                top: 20px;
                            }
                        }
                    }
                }
            }
            .content-right{
                width: 50%;
                @include max-mq(1200px){
                    width: 40%;
                }
                @include max-mq(768px){
                    width: 100%;
                    text-align: center;
                    margin-top: 50px;
                }
                img{
                    padding-left: 80px;
                    margin-top: -30px;
                    @include max-mq(1240px){
                        padding-left: 40px;
                        padding-right: 0px;
                    }
                    @include max-mq(768px){
                        padding-left: 0;
                        padding-right: 0;
                        margin-top: 0;
                    }
                }
            }
        }
    }
    
}

body .slick-list, .slick-slide {
    outline: none !important;
}


.slick-arrow{
    color: $light-blue;
    font-size: 30px;
    cursor: pointer;
    transition: all .4s;
    &:hover{
        color: $dark-blue;
    }
}

.slick-track{
    display: flex;
    align-items: center;
    @include max-mq(768px){
        position: relative;
        display: block;
    }
}


.slick-arrow.fa-chevron-right{
    position: relative;
    left: 5px;
}

.slick-arrow.fa-chevron-left{
    position: relative;
    right: 5px;
}